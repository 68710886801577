import { get_paginated_json } from './api_utils';
import {
  Voto, ThreadLista, FiltroVoto,
  FiltroProblemaThread
} from '../main/api/types';
import { plain_object } from 'django-rest-react';



export const get_difficolta = (filtro: FiltroVoto) => get_paginated_json<Voto>(`/api/problems/problemi/voto-difficolta/`, filtro as unknown as plain_object);
export const get_bellezza = (filtro: FiltroVoto) => get_paginated_json<Voto>(`/api/problems/problemi/voto-bellezza/`, filtro as unknown as plain_object);
export const get_problema_thread_list = (filtro?: FiltroProblemaThread) => get_paginated_json<ThreadLista>(`/api/problems/problemi/`, filtro as unknown as plain_object);
