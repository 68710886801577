import "core-js/stable";
import "regenerator-runtime/runtime";

import {
  make_request, http_get_params, http_post_params,
  http_delete_params, http_put_params, http_patch_params,
  plain_object, PaginatedJson
} from 'django-rest-react';

function get_page<T>(url: string, get_params?: plain_object) {
  return make_request<T>(url, http_get_params, undefined, get_params);
}

export function post_page<T>(url: string, formData: plain_object, get_params?: plain_object) {
  return make_request<T>(url, http_post_params, formData, get_params);
}

export function delete_page<T>(url: string, get_params?: plain_object) {
  return make_request<T>(url, http_delete_params, undefined, get_params);
}

export function put_page<T>(url: string, formData: plain_object, get_params?: plain_object) {
  return make_request<T>(url, http_put_params, formData, get_params);
}

export function patch_page<T>(url: string, formData: plain_object, get_params?: plain_object) {
  return make_request<T>(url, http_patch_params, formData, get_params);
}

export function get_single_json<T>(url: string, get_params?: plain_object) {
  return get_page<T>(url, get_params);
}


export function get_paginated_json<T>(url: string, get_params?: plain_object): Promise<T[]> {
  return get_page<PaginatedJson<T>>(url, get_params).then(json => {
    var result;
    if (json["next"] != null) {
      const next = json["next"].match(/(\?|&)page=([0-9]+)/g);
      const next2 = (next as string[])[0].match(/[0-9]+/g)
      const new_page = (next2 as string[])[0];

      const other_get_params = Object.assign({}, get_params);
      other_get_params["page"] = new_page;
      result = get_paginated_json<T>(
        url, other_get_params).then(function (other_json: T[]): T[] {
          return json["results"].concat(...other_json);
        });
    } else {
      result = json["results"];
    }
    return result;
  });
}
